import React from "react";
import theme from "theme";
import { Theme, Text, Image, LinkBox, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Hizmetlerimiz | Hizmetlerimiz - Cinamonos Balıkçılık Kulübü
			</title>
			<meta name={"description"} content={"Cinamonos Fishing Club'da sunduğumuz hizmetler, balıkçılık maceranızın her yönüne hitap edecek şekilde titizlikle tasarlanmıştır."} />
			<meta property={"og:title"} content={"Hizmetlerimiz | Hizmetlerimiz - Cinamonos Balıkçılık Kulübü"} />
			<meta property={"og:description"} content={"Cinamonos Fishing Club'da sunduğumuz hizmetler, balıkçılık maceranızın her yönüne hitap edecek şekilde titizlikle tasarlanmıştır."} />
			<meta property={"og:image"} content={"https://rescinamonos.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://rescinamonos.com/img/fishing-icons.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://rescinamonos.com/img/fishing-icons.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://rescinamonos.com/img/fishing-icons.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://rescinamonos.com/img/fishing-icons.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://rescinamonos.com/img/fishing-icons.png"} />
			<meta name={"msapplication-TileImage"} content={"https://rescinamonos.com/img/fishing-icons.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="90px 0 100px 0" quarkly-title="News/Streams/Blog-3">
			<Text as="h1" margin="0px 0px 15px 0px" font="normal 600 48px/1.2 --fontFamily-sans">
				Hizmetlerimiz
			</Text>
			<Text margin="0px 0px 60px 0px" font="--lead" color="#626970">
				Cinamonos Fishing Club'da sunduğumuz hizmetler, balıkçılık maceranızın her yönüne hitap edecek şekilde titizlikle tasarlanmıştır. Geldiğiniz andan son avınızı yakaladığınız ana kadar sorunsuz ve keyifli bir deneyim yaşamanızı sağlıyoruz.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="block"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="0 80px"
				lg-grid-gap="0 50px"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					margin="0px 0px 60px 0px"
					md-flex-direction="column"
				>
					<Image
						src="https://rescinamonos.com/img/4.jpg"
						display="block"
						width="35%"
						object-fit="cover"
						border-radius="25px"
						margin="0px 50px 0px 0px"
						height="350px"
						md-width="100%"
						md-margin="0px 50px 35px 0px"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						md-margin="0px 0px 30px 0px"
						margin="0px 0px 0 0px"
						width="65%"
						display="flex"
						flex-direction="column"
						md-width="100%"
						justify-content="flex-start"
					>
						<LinkBox align-items="flex-start" margin="0px 0px 15px 0px" href="/">
							<Text margin="0px 0px 0 0px" font="normal 600 24px/1.2 --fontFamily-sans">
								Kapsamlı Balıkçılık Hizmetleri
							</Text>
						</LinkBox>
						<Text
							margin="0px 0px 0 0px"
							font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
							lg-margin="0px 0px 40px 0px"
							color="#626970"
							md-margin="0px 0px 25px 0px"
						>
							Rehberli Balıkçılık Gezileri:
							<br />
							Uzmanlar Tarafından Yönetilen Maceralar - Yerel sularda deneyimli rehberlerimiz sizi en iyi noktalara götürecek ve verimli bir av için teknikleri paylaşacaktır.
							<br />
							Tüm Seviyelere Hoş Geldiniz - İster acemi ister deneyimli bir olta balıkçısı olun, gezilerimiz beceri seviyenize göre uyarlanmıştır.
							<br />
							<br />
							Kaliteli Ekipman Kiralama:
							<br />
							Birinci Sınıf Ekipman - Hafif seyahat edenler veya yeni teknikler deneyenler için mükemmel olan yüksek kaliteli oltalara, makaralara ve gerekli tüm balıkçılık ekipmanlarına erişim.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					margin="0px 0px 60px 0px"
					md-flex-direction="column"
				>
					<Image
						src="https://rescinamonos.com/img/5.jpg"
						display="block"
						width="35%"
						object-fit="cover"
						border-radius="25px"
						margin="0px 50px 0px 0px"
						height="350px"
						md-width="100%"
						md-margin="0px 50px 35px 0px"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						md-margin="0px 0px 30px 0px"
						margin="0px 0px 0 0px"
						width="65%"
						display="flex"
						flex-direction="column"
						md-width="100%"
						justify-content="flex-start"
					>
						<LinkBox align-items="flex-start" margin="0px 0px 15px 0px" href="/">
							<Text margin="0px 0px 0 0px" font="normal 600 24px/1.2 --fontFamily-sans">
								Konforlu Konaklama
							</Text>
						</LinkBox>
						<Text
							margin="0px 0px 0 0px"
							font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
							lg-margin="0px 0px 40px 0px"
							color="#626970"
							md-margin="0px 0px 25px 0px"
						>
							Rustik Ev Kiralamaları:
							<br />
							Tarzda Rahatlayın - Rahat evlerimiz, rustik cazibe ve modern konforun mükemmel bir karışımını sunar ve suda geçen bir günün ardından dinlenmek için idealdir.
							<br />
							<br />
							Yakala ve Pişir Servisi:
							<br />
							Gölden Tabağa - Gün içinde yakaladığınız balığın tadını çıkarmaktan daha iyi ne olabilir? Şeflerimiz balıklarınızı beğeninize göre hazırlayacak ve balık avı gününüze lezzetli bir son verecektir.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					margin="0px 0px 60px 0px"
					md-flex-direction="column"
				>
					<Image
						src="https://rescinamonos.com/img/6.jpg"
						display="block"
						width="35%"
						object-fit="cover"
						border-radius="25px"
						margin="0px 50px 0px 0px"
						height="350px"
						md-width="100%"
						md-margin="0px 50px 35px 0px"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						md-margin="0px 0px 30px 0px"
						margin="0px 0px 0 0px"
						width="65%"
						display="flex"
						flex-direction="column"
						md-width="100%"
						justify-content="flex-start"
					>
						<LinkBox align-items="flex-start" margin="0px 0px 15px 0px" href="/">
							<Text margin="0px 0px 0 0px" font="normal 600 24px/1.2 --fontFamily-sans">
								Ek Olanaklar
							</Text>
						</LinkBox>
						<Text
							margin="0px 0px 0 0px"
							font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
							lg-margin="0px 0px 40px 0px"
							color="#626970"
							md-margin="0px 0px 25px 0px"
						>
							Balıkçılık Atölyeleri - Yemleme tekniklerinden koruma uygulamalarına kadar balıkçılığın çeşitli yönlerini kapsayan atölye çalışmalarımızla becerilerinizi geliştirin.
							<br />
							<br />
							Topluluk Etkinlikleri - Düzenli topluluk etkinliklerinde, turnuvalarda ve sosyal toplantılarda balıkçılık meraklılarına katılın.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-16">
			<Override slot="SectionContent" flex-wrap="wrap" flex-direction="row" />
			<Box
				width="100%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					height="auto"
					transform="translateY(0px)"
					padding="0px 0px 50% 0px"
					width="100%"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
				>
					<Image
						src="https://rescinamonos.com/img/7.jpg"
						position="absolute"
						width="100%"
						bottom="0px"
						right={0}
						min-height="100%"
						object-fit="cover"
						display="block"
						top="auto"
						left={0}
					/>
				</Box>
			</Box>
			<Box
				sm-padding="0px 0px 0px 0px"
				justify-content="space-between"
				width="58%"
				flex-direction="row"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				lg-order="1"
				lg-justify-content="center"
				display="flex"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
			>
				<Box display="flex" flex-direction="column" md-width="100%">
					<Text
						font="--headline2"
						lg-text-align="center"
						sm-font="--headline3"
						sm-margin="0px 0px 20px 0px"
						margin="0px 0px 0px 0px"
						color="--darkL2"
						md-text-align="left"
					>
						Balıkçılık Kaçamağınızı Planlayın
					</Text>
				</Box>
			</Box>
			<Box
				sm-padding="0px 0px 0px 0px"
				lg-order="1"
				display="flex"
				width="40%"
				flex-direction="row"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				justify-content="space-between"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-padding="0px 16px 16px 16px"
			>
				<Box display="flex" flex-direction="column">
					<Text
						margin="0px 0px 0px 0px"
						color="--darkL2"
						font="--base"
						lg-text-align="center"
						md-text-align="left"
					>
						Hizmetlerimiz hakkında daha fazla bilgi almak ya da Cinamonos'de bir sonraki balık avınız için rezervasyon yaptırmak için lütfen doğrudan bizimle iletişime geçin. Balıkçılık deneyiminizin olağanüstü olmasını sağlamak için buradayız.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});